export default {
  name: "TaskList",
  data() {
    return {
      active: 0,

      loading: false,
      finished: false,
      list: [],

      searchCon: {
        page: 1,
        size: 10,
        status: null,
        status1: 33,
      },
    };
  },
  mounted() {
    document.title = "任务记录";
    this.getCurrentTaskList();
  },
  methods: {
    onLoad() {
      this.getCurrentTaskList();
    },
    getCurrentTaskList() {
      this.searchCon = {
        page: 1,
        size: 10,
        status: null,
        status1: 33,
      };
      this.$axios({
        method: "post",
        url: "/api/mobile/task/page",
        data: this.searchCon,
      }).then((res) => {
        this.list = res.data.data;
        this.loading = true;
        this.finished = true;
      });
    },
    getFinishTaskList() {
      this.searchCon = {
        page: 1,
        size: 10,
        status: 33,
        status1: null,
      };
      this.$axios({
        method: "post",
        url: "/api/mobile/task/page",
        data: this.searchCon,
      }).then((res) => {
        this.list = res.data.data;
        this.loading = true;
        this.finished = true;
      });
    },
    gotoDetail(item) {
      // if (item.status === 12) {
      //   this.$axios({
      //     method: "post",
      //     url: "/api/mobile/task/accomplishTask",
      //     data: {
      //       uuid: item.uuid,
      //     },
      //   }).then((res) => {
      //     console.log("res.data", res.data);
      //     if (res.data.code === 200) {
      //       const gotoUrl = res.data.data;
      //       window.location.href = gotoUrl;
      //     }
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/task/detail",
      //     query: {
      //       id: item.uuid,
      //     },
      //   });
      // }
      this.$router.push({
        path: "/task/detail",
        query: {
          id: item.uuid,
        },
      });
    },
    tabClick() {
      if (this.active === 0) {
        this.getCurrentTaskList();
      } else {
        this.getFinishTaskList();
      }
    },
    backHome() {
      this.$router.push("/home");
    },
  },
};
